import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PromoBlurb from '../PromoBlurb'

const GorgiasHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <PromoBlurb
      photo={photo}
      company="Gorgias"
      heading="Gorgias uses Swarmia to continuously improve the flow of delivery"
      subheading="Alex Plugaru, Co-Founder, CTO at Gorgias, tells how Swarmia helps his teams reduce cycle time to improve flow of delivery."
      featured={featured}
    />
  )
}

export default GorgiasHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/gorgias/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
